.navbar-header {
    float: none;
    height: auto;
    margin-right: -($grid-gutter-width);
    margin-left: -($grid-gutter-width / 2);
    padding: 0 ($grid-gutter-width / 2);
    background-color: #fff;
    @media (min-width: $screen-xl-min) {
        margin-right: -($xl-grid-gutter-width);
        margin-left: -(($xl-grid-gutter-width / 2));
        padding-right: 20px;
        padding-left: 20px;
    }
}

.navbar-brand {
    //@include hide-text;
    text-indent: -10000em;
    float: none;
    display: block;
    height: auto;
    margin: rem(10) 0;
    padding: 0;
    //background-image: url("#{$img-path}/logo.png");
    background-image: url("#{$img-path}/logo.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    line-height: rem(80);
}

.navbar-top,
.app + .nav-wrapper {
    @include themify-inverse($themes);
    font-family: $font-family-base-condensed;
    font-size: rem(31);
    text-transform: uppercase;

    .active {
        font-weight: 700;
    }

    ul {
        @include reset-list;
    }
}
.navbar-toggle {
    display: block;
    float: right;
    height: rem(100);
    margin: 0;
    padding: 0;
    border-radius: 0;
    vertical-align: middle;
    .icon-bar {
        width: rem(42);
        height: rem(6);
        background-color: #fff;
    }
}

.nav-trigger,
.nav-trigger-clone {

    .icon-bar {
        /* Force Hardware Acceleration */
        @include translate3d(0, 0, 0);
        @include backface-visibility(hidden);
        @include transition-transform(0.3s 0.3s);

        &:nth-child(2),
        &:nth-child(5) {
            @include transition(opacity 0.5s ease-in-out);
            opacity: 1;
        }
    }

    .nav-is-visible & .icon-bar {

        &:nth-child(2),
        &:nth-child(5) {
            opacity: 0;
        }
        &:nth-child(3) {
            -webkit-transform: translateY(80%) rotate(-45deg);
            -moz-transform: translateY(80%) rotate(-45deg);
            -ms-transform: translateY(80%) rotate(-45deg);
            -o-transform: translateY(80%) rotate(-45deg);
            transform: translateY(80%) rotate(-45deg);
        }
        &:nth-child(4) {
            -webkit-transform: translateY(-80%) rotate(45deg);
            -moz-transform: translateY(-80%) rotate(45deg);
            -ms-transform: translateY(-80%) rotate(45deg);
            -o-transform: translateY(-80%) rotate(45deg);
            transform: translateY(-80%) rotate(45deg);
        }
    }
}

@media (min-width: $grid-float-breakpoint) {
    .nav-wrapper {
        @include clearfix();
    }
    .nav-main {
        float: left;
    }
    .nav-meta {
        float: right;
    }
    .nav-main,
    .nav-meta {
        li {
            display: inline-block;
            vertical-align: middle;
            > a {
                position: relative;
                bottom: -0.1em;
                display: inline-block;
                height: rem(100);
                line-height: rem(100);
            }
        }
    }
    .nav-main {
        a {
            padding-left: rem(16);
            padding-right: rem(16);
            @media (min-width: $screen-xl-min) {
                padding-left: rem(24);
                padding-right: rem(24);
            }
        }
    }
    .nav-meta {
        li {
            padding-left: rem(12);
            padding-right: rem(12);

            a + a {
                &:before {
                    position: relative;
                    top: -.1em;
                    content: '';
                    margin-left: rem(6);
                    padding-left: rem(6);
                    border-left: 1px solid #fff;
                }
            }
        }
    }
}
@media (max-width: $grid-float-breakpoint-max) {
    .nav-wrapper {
        @include themify-inverse($themes);
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        //visibility: hidden;
        width: 260px;
        margin-bottom: 0;
        padding: 0 $grid-gutter-width;
        @include background-clip();
        //@include transition(visibility 0s 0.3s);
        -webkit-overflow-scrolling: touch;
        @include transition-transform(0.3s);
        @include translate(260px, 0);

        &.nav-is-visible {
            //visibility: visible;
            //@include transition(visibility 0s 0s);
            @include translate(0, 0);
        }

        .nav-main {
            padding-top: rem(24);
            line-height: rem(48);
        }
        .nav-meta {
            border-bottom: 1px solid rgba(#fff, .3);
            li {
                display: inline-block;
                margin-right: $grid-gutter-width;
                > a {
                    position: relative;
                    bottom: -0.1em;
                    display: inline-block;
                    height: rem(100);
                    line-height: rem(100);
                }
                a + a {
                    &:before {
                    position: relative;
                    top: -.1em;
                    content: '';
                    margin-left: rem(6);
                    padding-left: rem(6);
                    border-left: 1px solid #fff;
                    }
                }
             }
        }
    }


    .navbar-top {
        @include background-clip();
        //@include transition(visibility 0s 0.3s);
        -webkit-overflow-scrolling: touch;
        @include transition-transform(0.3s);
        @include translate(0, 0);

        &.nav-is-visible {
            //visibility: visible;
            //@include transition(visibility 0s 0s);
            @include translate(-260px, 0);
        }
    }
}

.breadcrumb {
    margin: rem(32) 0;
    font-family: $font-family-base-condensed;
    font-size: $fs-5;
    a {
        color: $text-color;
    }
    a + a {
        margin-left: 10px;
        &:before {
            content: '>';
            display: inline-block;
            margin-right: 10px;
        }
    }
}




@media (min-width: $grid-float-breakpoint) {
    .nav-wrapper-clone .nav-wrapper {
        @include themify-inverse($themes);
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        //visibility: hidden;
        width: 260px;
        margin-bottom: 0;
        padding: 0 $grid-gutter-width;
        @include background-clip();
        //@include transition(visibility 0s 0.3s);
        -webkit-overflow-scrolling: touch;
        @include transition-transform(0.3s);
        @include translate(260px, 0);

        &.nav-is-visible {
            //visibility: visible;
            //@include transition(visibility 0s 0s);
            @include translate(0, 0);
        }

        .nav-main {
            padding-top: rem(24);
            //line-height: rem(48);
            .nav-main-list {
                @include reset-list();
                > li {
                    display: block;
                    margin-bottom: rem(20);
                    > a {
                        height: auto;
                        padding: 0;
                        line-height: rem(24);
                        text-transform: uppercase;
                    }
                }
            }
        }
        .nav-meta {
            float: none;
            border-bottom: 1px solid rgba(#fff, .3);
            .nav-meta-list {
                @include reset-list();

                // doppelter nav-trigger-clone verstecken
                .hidden-xs {
                     display: none;
                }
            }
            li {
                display: inline-block;
                margin-right: $grid-gutter-width;
                > a {
                    position: relative;
                    bottom: -0.1em;
                    display: inline-block;
                    height: rem(100);
                    line-height: rem(100);
                }
                a + a {
                    &:before {
                    position: relative;
                    top: -.1em;
                    content: '';
                    margin-left: rem(6);
                    padding-left: rem(6);
                    border-left: 1px solid #fff;
                    }
                }
             }
        }
    }


    .navbar-top {
        @include background-clip();
        //@include transition(visibility 0s 0.3s);
        -webkit-overflow-scrolling: touch;
        @include transition-transform(0.3s);
        @include translate(0, 0);

        &.nav-is-visible {
            //visibility: visible;
            //@include transition(visibility 0s 0s);
            @include translate(-260px, 0);
        }
    }
}


.slider-container,
.image-container {
    margin-right: -($grid-gutter-width / 2);
    margin-left: -($grid-gutter-width / 2);

    @media (min-width: $screen-xl-min) {
        margin-right: -($xl-grid-gutter-width / 2);
        margin-left: -($xl-grid-gutter-width / 2);
    }
}
.slider-container {
    position: relative;
}
.slider {
    margin-bottom: rem(135);
}
.slider-jobs,
.slider-news {
    margin-bottom: rem(35);
    text-align: center;
    @media (min-width: $grid-float-breakpoint) {
        position: absolute;
        bottom: rem(190);
        right: 0;
        z-index: 99;
        margin-bottom: 0;
        padding-left: $grid-gutter-width;
        text-align: left;
    }
    @media (min-width: $screen-lg-min) {
        max-width: 230px;
    }
    @media (min-width: $screen-xl-min) {
        bottom: rem(240);
        max-width: 260px;
        padding-left: $xl-grid-gutter-width;
    }

    .jobs,
    .news {
        padding: ($grid-gutter-width / 3);
        @media (min-width: $screen-sm-min) {
            padding: ($grid-gutter-width / 2);
        }
        @media (min-width: $screen-xl-min) {
            padding: ($xl-grid-gutter-width / 2);
        }
        background-color: #fff;
        font-family: $font-family-base-condensed;
        font-size: $fs-3;

        h3 {
            margin-top: 0;
            color: $brand-primary;
            font-size: rem(35);
            font-weight: 700;
            text-transform: uppercase;
            > i {
                margin-right: 5px;
            }
        }
        p {
            margin: 0;
        }
        a {
            color: $text-color;
            text-decoration: underline;
        }
    }
}
.slider-jobs {
    @media (min-width: $grid-float-breakpoint) {
        bottom: rem(20);
    }
    @media (min-width: $screen-lg-min) {
    }
    @media (min-width: $screen-xl-min) {
        bottom: rem(40);
    }
}

.owl-nav {
    /*
    position: absolute;
    left: rem(32);
    right: rem(32);
    bottom: rem(50);
    z-index: 100;
    */


    .owl-prev,
    .owl-next {
        position: absolute;
        bottom: rem(30);
        z-index: 100;
        color: rgba(#fff, .5);
        font-size: rem(120);
        line-height: rem(120);
        text-align: center;
    }
    .owl-prev {
        left: rem(32);
    }
    .owl-next {
        right: rem(32);
    }
}
.owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(-63);
    line-height: 1;
    text-align: center;
}
.owl-dot {
    @include themify-border($themes);
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    vertical-align: top;
    &.active {
        @include themify-background($themes);
    }
}

.start {
    @include yak-container();
    margin-top: rem(40);
    font-size: rem(30);
    font-weight: 200;
    line-height: rem(40);
    text-align: center;

    h1 {
        margin-top: rem(42);
        margin-bottom: rem(65);
        font-size: rem(60);
    }
}

// $grid - um das Raster innerhalb ein bootstrap col- zu halten.
//
$grid-columns: 10;
.grid-row {
    @include make-row();
    @media (min-width: $screen-xl-min) {
        margin-left: -($xl-grid-gutter-width / 2);
        margin-right: -($xl-grid-gutter-width / 2);
    }

    .grid-col-xs-2 {
        @include make-xs-column(2);
    }
    .grid-col-xs-3 {
        @include make-xs-column(3);
    }
    .grid-col-xs-4 {
        @include make-xs-column(4);
    }
    .grid-col-xs-5 {
        @include make-xs-column(5);
    }
    .grid-col-xs-6 {
        @include make-xs-column(6);
    }
    .grid-col-xs-10 {
        @include make-xs-column(10);
    }
    .grid-col-xs-12 {
        @include make-xs-column(10);
    }

    .grid-col-sm-2 {
        @include make-sm-column(2);
    }
    .grid-col-sm-3 {
        @include make-sm-column(3);
    }
    .grid-col-sm-4 {
        @include make-sm-column(4);
    }
    .grid-col-sm-5 {
        @include make-sm-column(5);
    }
    .grid-col-sm-6 {
        @include make-sm-column(6);
    }
    .grid-col-sm-10 {
        @include make-sm-column(10);
    }
    .grid-col-sm-12 {
        @include make-sm-column(10);
    }

    [class*=grid-col-] {
        @media (min-width: $screen-xl-min) {
            padding-left: ($xl-grid-gutter-width / 2);
            padding-right: ($xl-grid-gutter-width / 2);
        }
    }

}

$grid-columns: 12;

$slice-distance: 40;
.slice {
    margin-top: rem($slice-distance);
    margin-bottom: rem($slice-distance);
}
.slice-process {
    & + & {
        margin-top: (-(rem($slice-distance)));
    }
}
.product-promotion {
    margin-top: rem(114);
    margin-bottom: rem(78);
}

.main-left,
.main-right {
     .side-text {
         & + .slice-contact-card {
            margin-top: rem(190);
         }
     }
    .slice-image,
    .slice-contact-card {
        & + .slice-image,
        & + .slice-contact-card {
            margin-top: -(rem(16));
        }
    }
}

.main-right .image-collection {
    margin-top: 0;
}


.slice-process {
    & + &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        margin: rem($slice-distance + 20) auto (-(rem(52 - 15))) auto; // 52 border-bottom und 15px definierter Abstand laut Layout
        border: rem(32) solid transparent;
        border-top-width: rem(52);
        border-bottom-width: rem(52);
        @include themify-process($themes);
    }
}


.intro {
    font-size: rem(30);
    font-weight: 200;
    line-height: rem(40);

    h1 {
        text-transform: uppercase;
    }
    p {
        margin-bottom: rem(40);
    }
    a {
        color: $text-color;
        text-decoration: underline;
        &:hover {
            @include themify-color($themes);
        }
    }
    strong, b {
        @include themify-color($themes);
    }
    aside {
        @include themify-background($themes);
        padding: ($grid-gutter-width / 2);
        color: #fff;
        font-family: $font-family-base;
        font-size: rem(24);
        font-weight: 400;
        line-height: rem(32);

        @media (min-width: $screen-xl-min) {
            padding: ($xl-grid-gutter-width / 2);
        }

        h2 {
            margin-top: 0;
            margin-bottom: .5em;
            font-size: $fs-1;
        }
    }
}

.card {
    position: relative;
    margin-top: ($grid-gutter-width / 2);
    margin-bottom: ($grid-gutter-width / 2);
    font-family: $font-family-base-condensed;
    @media (min-width: $screen-xl-min) {
        margin-top: ($xl-grid-gutter-width / 2);
        margin-bottom: ($xl-grid-gutter-width / 2);
    }
    .card-title {
        position: absolute;
        left: 30%;
        right: 0;
        bottom: 0;
        margin: 0;
        padding-left: rem(24);
        padding-right: rem(24);
        background-color: #fff;
        color: $text-color;
        font-size: rem(35);
        font-weight: 600;
        line-height: rem(60);
        text-align: right;
    }
}

.contact-card {
    .image-collection & {
        margin-top: $grid-gutter-width;
        @media (min-width: $screen-md-min) {
            min-height: 360px;
        }
        @media (min-width: $screen-lg-min) {
            min-height: 436px;
        }
        @media (min-width: $screen-xl-min) {
            min-height: 490px;
            margin-top: $xl-grid-gutter-width;
        }
    }

    @include themify-background($themes);
    padding: rem(20);

    &, a {
        color: #fff;
    }

    > table > tbody > tr > th {
        padding-right: rem(20);
    }
    > table > tbody > tr > th,
    > table > tbody > tr > td {
        padding-bottom: 1em;
    }

    .contact-heading {
        margin-bottom: rem(16);
        border-bottom: 1px solid #fff;
    }
}

.contact-panel {
    @include themify-background($themes);
    padding: rem(20);

    &, a {
        color: #fff;
    }
    .contact-heading {
        margin-bottom: 0;
    }
}

.contact-heading {
    margin-top: 0;
    font-size: $fs-1;
}

.contact-data {
    @include reset-list;
    font-family: $font-family-base-condensed;
    font-size: rem(38);
    font-weight: 600;
}

.image-collection {
    &.with-side-text {
    }
    img {
        margin-top: $grid-gutter-width;
        @media (min-width: $screen-xl-min) {
            margin-top: $xl-grid-gutter-width;
        }
    }
}

.image-image {
    img {
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        @media (min-width: $screen-xl-min) {
            margin-top: $xl-grid-gutter-width;
            margin-bottom: $xl-grid-gutter-width;
        }
    }
}

.logo-collection {
    a {
        color: $text-color;
    }
    .collection-item {
        min-height: 132px;
        margin-top: $grid-gutter-width;
        padding: rem(10);
        border: 1px solid $text-color;
        @media (min-width: $screen-xl-min) {
            margin-top: $xl-grid-gutter-width;
        }
        > img {
            max-width: 60%;
            margin-right: 2px;
        }
    }
    .collection-title {
        float: right;
        max-width: 40%;
        color: $text-color;
        font-size: rem(14);
        font-family: $font-family-base-condensed;
        line-height: rem(16);
        text-align: right;
    }
}

.logo-square-collection {
    .collection-item {
        position: relative;
        //height: 230px;

        @media (max-width: $screen-xs-max) {
            min-height: 72px;
        }
        @media (min-width: $screen-sm-min) {
            height: 125px;
        }
        @media (min-width: $screen-md-min) {
            height: 168px;
        }
        @media (min-width: $screen-lg-min) {
            height: 206px;
        }
        @media (min-width: $screen-xl-min) {
            height: 230px;
        }
        > img {
            position: absolute;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            @include transform(translateX(-50%) translateY(-50%));
            //max-width: none;
            max-height: 60%;
            margin-right: 0;
        }
    }
}

.process {
    @include themify-background($themes);
    padding: rem(30) $grid-gutter-width;

    @media (min-width: $screen-xl-min) {
        padding-left: $xl-grid-gutter-width;
        padding-right: $xl-grid-gutter-width;
    }

    &, a {
        color: #fff;
    }

    .process-heading {
        margin-top: 0;
        margin-bottom: rem(16);
        font-size: $fs-1;
    }
}

.product-promotion {
    h1, h2, h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
    h1 {
        font-size: rem(200);
        line-height: 1;
        text-transform: uppercase;
    }
    h3 {
        font-family: $font-family-base-condensed;
        font-size: $fs-2;
        font-weight: 600;
        text-transform: uppercase;
    }
    .product-promotion-header,
    .product-promotion-text {
        padding: rem(20);
        @media (min-width: $screen-sm-min) {
            min-height: 125px;
        }
        @media (min-width: $screen-md-min) {
            min-height: 168px;
        }
        @media (min-width: $screen-lg-min) {
            min-height: 206px;
        }
        @media (min-width: $screen-xl-min) {
            min-height: 230px;
        }
    }
    .product-promotion-header {
        @include themify-background($themes);
        color: #fff;
        @media (max-width: $screen-xs-max) {
            margin-bottom: rem($slice-distance)
        }
        h1 {
            margin-top: -(rem(28));
            margin-left: -(rem(13));
            margin-bottom: -(rem(20));
        }
    }
    .product-promotion-text {
        @include themify-background-light($themes);
        font-weight: 300;
        strong, b {
            @include themify-color($themes);
        }
        a {
            color: $text-color;
            font-weight: 600;
            text-decoration: underline;
            &:hover {
                @include themify-color($themes);
            }
        }
        p:last-child {
            margin-bottom: 0;
        }
    }
}
.product-range {
    padding-top: rem(74);
    border-top: 1px solid $text-color;
    font-weight: 300;

    & + & {
        margin-top: rem(74);
    }

    strong, b {
        @include themify-color($themes);
    }
    p:last-child {
        margin-bottom: 0;
    }
}
.side-text {
    font-weight: 300;
    .header {
        //font-size: rem(32);
        @include themify-border($themes);
        margin-top: rem(37);
        margin-bottom: .5em;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        line-height: rem(42);
    }
    .body {
        h3 {
            margin: 0;
            font-size: $fs-base;
            font-weight: 600;
            line-height: rem(32);
        }
        ul {
            padding-left: 0;
            list-style: none;
            > li {
                padding-left: rem(25);
                &:before {
                    content: '+';
                    float: left;
                    margin-left: -(rem(25));
                    @include themify-color($themes);
                }
            }
        }
        strong, b {
            @include themify-color($themes);
        }
        a {
            color: $text-color;
            text-decoration: underline;
            &:hover {
                @include themify-color($themes);
            }
        }

        table {
            > tbody > tr > th,
            > tbody > tr > td {
                padding-right: 5px;
                border-bottom: 5px solid #fff;
                font-weight: 300;
                vertical-align: top;
            }
            > tbody > tr > td {
                padding-left: 5px;
                &:before {
                    content: '';
                    margin-left: -6px;
                    margin-right: 4px;
                    border-left: 1px solid $text-color;
                }
            }
        }
    }
    .body.force-color-text {
        strong, b {
            color: $text-color;
        }
    }
}

.small-logo-collection {
    margin-left: -(rem(8));
    margin-right: -(rem(8));
    .collection-item {
        display: inline-block;
        margin-left: rem(8);
        margin-right: rem(8);
    }
}


.site-search {
    margin-bottom: rem($slice-distance * 2);
}
.site-search-wrapper {
    //display: table-cell;
    position: relative;
    z-index: 10000;
    //line-height: 1;
}
.site-search-input {
    width: 100%;
    height: rem(50);
    padding: 0 $grid-gutter-width;
    background-color: #fff;
    border: 1px solid $gray;
    border-radius: 6px;
    box-shadow: 0 0 0 0 $gray-light inset;
    color: $text-color;
    font-size: $fs-5;
    line-height: rem(50);

    @media (min-width: $screen-xl-min) {
        padding: ($xl-grid-gutter-width / 2) $xl-grid-gutter-width;
    }

    &:focus {
        outline: 0;
    }
}
.site-search-blur {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9900;
    background-color: $gray;
    opacity: .5;
    transition: all 0.2s ease;
}
.site-search-focus {
    .site-search-input {
        border-color: #fff;
    }
    .site-search-blur {
        display: block;
    }
}
.site-search-highlight {
    color: $brand-primary;
    font-weight: 600;
}
.site-search-result {
    margin-bottom: rem($slice-distance * 2);
    font-weight: 300;
    h2 {
        @include themify-border($themes);
        margin-top: rem(37);
        margin-bottom: .5em;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        line-height: rem(42);
    }
    p {
        margin: 0;
    }
}

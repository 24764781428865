@mixin background-clip($clip: padding-box) {
    -webkit-background-clip: $clip;
    -mox-background-clip: $clip;
    -o-background-clip: $clip;
    background-clip: $clip;
}


@mixin keyframes( $animationName ) {
    @-webkit-keyframes $animationName {
        @content;
    }
    @-moz-keyframes $animationName {
        @content;
    }
    @-o-keyframes $animationName {
        @content;
    }
    @keyframes $animationName {
        @content;
    }
}


@mixin transform-style($val) {
    -webkit-transform-style: $val;
    -moz-transform-style: $val;
    -ms-transform-style: $val;
    transform-style: $val;
}


@mixin translateX($x) {
  -webkit-transform: translateX($x);
     -moz-transform: translateX($x);
      -ms-transform: translateX($x); // IE9 only
       -o-transform: translateX($x);
          transform: translateX($x);
}
@mixin translateY($y) {
  -webkit-transform: translateX($y);
     -moz-transform: translateX($y);
      -ms-transform: translateX($y); // IE9 only
       -o-transform: translateX($y);
          transform: translateX($y);
}
//$xl-grid-gutter-width: $grid-gutter-width !default;
$xl-grid-gutter-width: 30px;

// XLarge screen
$screen-xl: 1700px !default;
$screen-xl-min: $screen-xl !default;
$screen-xl-desktop: $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-lg-max: ($screen-xl-min - 1) !default;

//== Container sizes
// Large screen / wide desktop
$container-xlarge-desktop: ((1530px + $xl-grid-gutter-width)) !default;
$container-xl: $container-xlarge-desktop !default;

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
.container {
    @media (min-width: $screen-xl-min) {
        width: $container-xl;
        padding-left: ($xl-grid-gutter-width / 2);
        padding-right: ($xl-grid-gutter-width / 2);
    }
}
.row {
    @media (min-width: $screen-xl-min) {
        margin-left: -($xl-grid-gutter-width / 2);
        margin-right: -($xl-grid-gutter-width / 2);
    }
}
[class*=col-] {
    @media (min-width: $screen-xl-min) {
        padding-left: ($xl-grid-gutter-width / 2);
        padding-right: ($xl-grid-gutter-width / 2);
    }
}

@mixin make-grid-xlcolumns($i: 1, $list: ".col-xl-#{$i}") {

    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xl-#{$i}";
    }

    #{$list} {
        @media (min-width: $screen-xl-min) {
            position: relative;
            // Prevent columns from collapsing when empty
            min-height: 1px;
            // Inner gutter via padding
            padding-left: ($xl-grid-gutter-width / 2);
            padding-right: ($xl-grid-gutter-width / 2);
        }
    }

}

@include make-grid-xlcolumns();
@media (min-width: $screen-xl-min) {
    @include make-grid(xl);
}

// Generate the large columns
@mixin make-xl-column($columns, $gutter: $xl-grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);

    @media (min-width: $screen-xl-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-offset($columns) {
    @media (min-width: $screen-xl-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-push($columns) {
    @media (min-width: $screen-xl-min) {
        left: percentage(($columns / $grid-columns));
    }
}

@mixin make-xl-column-pull($columns) {
    @media (min-width: $screen-xl-min) {
        right: percentage(($columns / $grid-columns));
    }
}

@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
    display: none !important;
}

@media (min-width: $screen-xl-min) {
    @include responsive-visibility('.visible-xl');
}

.visible-xl-block {
    @media (min-width: $screen-xl-min) {
        display: block !important;
    }
}

.visible-xl-inline {
    @media (min-width: $screen-xl-min) {
        display: inline !important;
    }
}

.visible-xl-inline-block {
    @media (min-width: $screen-xl-min) {
        display: inline-block !important;
    }
}

@media (min-width: $screen-xl-min) {
    @include responsive-invisibility('.hidden-xl');
}


@mixin themify-color($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, color);
        }
    }
}
@mixin themify-background($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            background-color: map-get($map, color);
        }
    }
}
@mixin themify-background-light($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            background-color: map-get($map, inverse-light-background);
        }
    }
}
@mixin themify-border($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            border-color: map-get($map, color);
        }
    }
}
@mixin themify-border-light($themes: $themes, $property: "") {
    @if ($property == '') {
        $property: "border-color";
    } @else {
        $property: "border-#{$property}-color";
    }
    @each $theme, $map in $themes {
        .#{$theme} & {
            #{$property}: map-get($map, inverse-light-background);
        }
    }
}
@mixin themify-inverse($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            background-color: map-get($map, inverse-background);
            color: map-get($map, inverse-text-color);

            a {
                color: map-get($map, inverse-link-color);
                &:hover {
                    color: map-get($map, inverse-link-color-hover);
                }
            }
        }
    }
}
@mixin themify-process($themes: $themes) {
    @each $theme, $map in $themes {
        .#{$theme} & {
            border-top-color: map-get($map, process-color);
        }
    }
}

/*
@font-face {
    font-family: 'Myriad Pro';
    src: local('MyriadPro-Light');
    font-style: normal;
    font-weight: 200;
}
@font-face {
    font-family: 'Myriad Pro';
    src: local('MyriadPro-Regular');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Myriad Pro';
    src: local('MyriadPro-Semibold');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Myriad Pro Condensed';
    src: local('MyriadPro-Cond');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Myriad Pro Condensed';
    src: local('MyriadPro-SemiboldCond');
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: 'Myriad Pro Condensed';
    src: local('MyriadPro-BoldCond');
    font-style: normal;
    font-weight: 700;
}
*/





@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=945f0bf0-3ce6-455a-a95f-a205caea6cd3");
@font-face {
    font-family:"myriad-pro";
    src:url("#{$font-path}/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix");
    src:url("#{$font-path}/b3e9f984-f8ca-4650-90c3-d6f0aca0a27d.eot?#iefix") format("eot"),url("#{$font-path}/e7e30ff2-3a95-49b0-bbf9-024f40ead426.woff2") format("woff2"),url("#{$font-path}/b984f4e8-e37d-4502-bead-ffd991e64d1f.woff") format("woff"),url("#{$font-path}/82d18baa-8811-4aa8-9338-9bafa08350d0.ttf") format("truetype"),url("#{$font-path}/20e14bb9-6aec-47a9-8ef8-5613bf1d6eae.svg#20e14bb9-6aec-47a9-8ef8-5613bf1d6eae") format("svg");
    font-weight: 200;
}
@font-face{
    font-family:"myriad-pro";
    src:url("#{$font-path}/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix");
    src:url("#{$font-path}/7f8bf145-43b4-4e18-8ab6-38552ac83b70.eot?#iefix") format("eot"),url("#{$font-path}/6ad65c4a-999a-4b06-92ad-075dd9a6c0a7.woff2") format("woff2"),url("#{$font-path}/3efa8eef-d81a-4a5b-ba15-94c5afa463cf.woff") format("woff"),url("#{$font-path}/0ac3a8d1-ea58-4bc8-b995-cd7fce51290e.ttf") format("truetype"),url("#{$font-path}/f360572f-8d74-4e11-b63c-91a55e17c82d.svg#f360572f-8d74-4e11-b63c-91a55e17c82d") format("svg");
    font-style: italic;
    font-weight: 200;
}
@font-face{
    font-family:"myriad-pro";
    src:url("#{$font-path}/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix");
    src:url("#{$font-path}/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix") format("eot"),url("#{$font-path}/3b0f1c67-c2e4-4df6-976f-49d52e45aba1.woff2") format("woff2"),url("#{$font-path}/c5b1c170-d8f7-41f9-85c2-0ab670780c6b.woff") format("woff"),url("#{$font-path}/ba1ff8f6-0c27-4e7b-8be5-818b6c3dd801.ttf") format("truetype"),url("#{$font-path}/a379413e-a0b8-44dc-b250-1e000e4092a4.svg#a379413e-a0b8-44dc-b250-1e000e4092a4") format("svg");
    font-weight: 400;
}
@font-face{
    font-family:"myriad-pro";
    src:url("#{$font-path}/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix");
    src:url("#{$font-path}/b7dea71a-f8e7-42e0-b03b-ae1b0b6eb0cd.eot?#iefix") format("eot"),url("#{$font-path}/a56f9fd5-b438-4696-ae62-d273eb2e4c1b.woff2") format("woff2"),url("#{$font-path}/7becdf28-cd45-4f8a-bcab-d8c861a8ebc5.woff") format("woff"),url("#{$font-path}/b90a1a2e-a700-4528-aa00-535c93ecf8a9.ttf") format("truetype"),url("#{$font-path}/85c745b1-d826-4e09-988f-82cd152fb0db.svg#85c745b1-d826-4e09-988f-82cd152fb0db") format("svg");
    font-style: italic;
    font-weight: 400;
}
@font-face{
    font-family:"myriad-pro";
    src:url("#{$font-path}/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix");
    src:url("#{$font-path}/f71415c0-f68d-42cd-a948-05af81548ea5.eot?#iefix") format("eot"),url("#{$font-path}/0b6110f9-6072-46b9-98af-7d09f7c895b8.woff2") format("woff2"),url("#{$font-path}/c22866d7-ca67-4170-a113-cae280eea669.woff") format("woff"),url("#{$font-path}/19ce9ea9-076e-4dcd-91a1-454f4830f120.ttf") format("truetype"),url("#{$font-path}/18915124-98e4-4245-9e10-b921e09a6704.svg#18915124-98e4-4245-9e10-b921e09a6704") format("svg");
    font-weight: 700;
}
@font-face{
    font-family:"myriad-pro";
    src:url("#{$font-path}/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix");
    src:url("#{$font-path}/e3c5d5a6-3f08-4d0c-a4af-3d90f6fa03ee.eot?#iefix") format("eot"),url("#{$font-path}/2cc3ff2f-19fe-458d-99da-2fb1acb43d81.woff2") format("woff2"),url("#{$font-path}/f83744dc-5c76-4309-bea0-03c18f58997f.woff") format("woff"),url("#{$font-path}/dc5e0550-21b2-4664-a54a-248791c96660.ttf") format("truetype"),url("#{$font-path}/f1682532-45c5-4c1b-a058-a65e600e0100.svg#f1682532-45c5-4c1b-a058-a65e600e0100") format("svg");
    font-style: italic;
    font-weight: 700;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/9365313d-8496-4657-86cb-439a81698e62.eot?#iefix");
    src:url("#{$font-path}/9365313d-8496-4657-86cb-439a81698e62.eot?#iefix") format("eot"),url("#{$font-path}/db3cef8e-edd0-4e0b-b59f-41d34fed49f7.woff2") format("woff2"),url("#{$font-path}/d7f07558-358e-43ab-bd36-0298eee9fad1.woff") format("woff"),url("#{$font-path}/408c195f-7817-4e58-b9a6-199195365c7a.ttf") format("truetype"),url("#{$font-path}/42f31449-1991-4ca6-bb98-9e924d681719.svg#42f31449-1991-4ca6-bb98-9e924d681719") format("svg");
    font-weight: 400;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/3c12e3db-1b36-4213-9e7b-a90b13cfeedf.eot?#iefix");
    src:url("#{$font-path}/3c12e3db-1b36-4213-9e7b-a90b13cfeedf.eot?#iefix") format("eot"),url("#{$font-path}/4a31b3d0-0105-45c4-a7a0-6af0e8574340.woff2") format("woff2"),url("#{$font-path}/dadf64e3-e1b9-4398-aa0c-74a0e4bd0afe.woff") format("woff"),url("#{$font-path}/da6465dc-024e-474f-b79f-cfc753179025.ttf") format("truetype"),url("#{$font-path}/d262073f-a668-46df-84b7-250ef7900a32.svg#d262073f-a668-46df-84b7-250ef7900a32") format("svg");
    font-style: italic;
    font-weight: 400;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/4653ef9b-e5ce-4c59-9ec1-0af6638fca0e.eot?#iefix");
    src:url("#{$font-path}/4653ef9b-e5ce-4c59-9ec1-0af6638fca0e.eot?#iefix") format("eot"),url("#{$font-path}/d488558f-a9ea-4e8a-b318-f7ab5cd9357a.woff2") format("woff2"),url("#{$font-path}/a2f2aae6-fded-4f03-897a-84e978797dfd.woff") format("woff"),url("#{$font-path}/998b20b6-d1f1-4500-8ec7-6f82276cc8ac.ttf") format("truetype"),url("#{$font-path}/ea5e5d84-0a40-4c45-a134-5d475db7f788.svg#ea5e5d84-0a40-4c45-a134-5d475db7f788") format("svg");
    font-weight: 600;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/a897e4ee-c11c-43c7-bd6e-9d0282f8ced4.eot?#iefix");
    src:url("#{$font-path}/a897e4ee-c11c-43c7-bd6e-9d0282f8ced4.eot?#iefix") format("eot"),url("#{$font-path}/aece0ba3-eed2-4cab-9458-b98af8ae5e02.woff2") format("woff2"),url("#{$font-path}/11735dc1-d8f5-42d8-9ff2-faac7f94294a.woff") format("woff"),url("#{$font-path}/1ffdd3d6-c2ba-46cf-acd5-8d9b1d7a45ce.ttf") format("truetype"),url("#{$font-path}/dac59f53-a56d-4ab8-89bb-d1f2cad55e2a.svg#dac59f53-a56d-4ab8-89bb-d1f2cad55e2a") format("svg");
    font-style: italic;
    font-weight: 600;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/b4df0776-43d3-4954-9ef2-95527dcfb011.eot?#iefix");
    src:url("#{$font-path}/b4df0776-43d3-4954-9ef2-95527dcfb011.eot?#iefix") format("eot"),url("#{$font-path}/f3750fd8-e60c-4354-9fd6-584d74d1d19e.woff2") format("woff2"),url("#{$font-path}/4823eae2-791d-4382-8ef0-5916b5b1623f.woff") format("woff"),url("#{$font-path}/5f217c58-a6c1-4caa-a812-82aebe639d32.ttf") format("truetype"),url("#{$font-path}/d13b5353-c61b-48c5-a822-a1ae523eddf6.svg#d13b5353-c61b-48c5-a822-a1ae523eddf6") format("svg");
    font-weight: 700;
}
@font-face{
    font-family:"myriad-pro-condensed";
    src:url("#{$font-path}/2919f433-b272-41e3-95c9-417ef6cd34e1.eot?#iefix");
    src:url("#{$font-path}/2919f433-b272-41e3-95c9-417ef6cd34e1.eot?#iefix") format("eot"),url("#{$font-path}/153312be-58e0-4a1c-adea-10c3a0ecbb1b.woff2") format("woff2"),url("#{$font-path}/00252721-ef30-49ec-80cb-93b1bea795cb.woff") format("woff"),url("#{$font-path}/21cca651-1cd3-4115-996f-f8257b0bc07a.ttf") format("truetype"),url("#{$font-path}/f7006adb-c3c7-4e07-b020-ec556658e45a.svg#f7006adb-c3c7-4e07-b020-ec556658e45a") format("svg");
    font-style: italic;
    font-weight: 700;
}

html {
    font-size: 87.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (min-width: $screen-lg-min) {
        font-size: 100%;
    }
    @media (min-width: $screen-xl-min) {
        font-size: 125%;
    }
}

button {
    -webkit-font-smoothing: inherit;
}
html, body {
    height: 100%;
}
body {
    padding-top: rem(100);
    font-size: inherit;
}

body:before {
    display: none;
    content: "xs";
    @media (min-width: $screen-sm-min) {
        content: "sm";
    }
    @media (min-width: $screen-md-min) {
        content: "md";
    }
    @media (min-width: $screen-lg-min) {
        content: "lg";
    }
    @media (min-width: $screen-xl-min) {
        content: "xl";
    }
}

.app {
    position: relative;
    font-size: rem(24);

    min-height: 100%;
    @media (min-width: $grid-float-breakpoint) {
        padding-bottom: 400px;
    }

    /* Force Hardware Acceleration */
    @include translate3d(0, 0, 0);
    @include backface-visibility(hidden);
    @include transition-transform(0.3s);

    &.nav-is-visible {
        @include translate(-260px, 0);
        /*
        @media (min-width: $grid-float-breakpoint) {
            @include translate(0, 0);
        }
        */
    }
}

.global-footer {
    @media (min-width: $grid-float-breakpoint) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    background-color: $gray;
    @media (min-width: $screen-xl-min) {
        padding-top: $xl-grid-gutter-width;
        padding-bottom: $xl-grid-gutter-width;
    }
    &,
    a {
        color: #fff;
    }
    h3 {
        max-width: 360px;
        border-bottom: 2px dotted #fff;
        font-family: $font-family-base-condensed;
        font-size: rem(31);
        font-weight: 700;
        text-transform: uppercase;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }
    ul {
        @include reset-list;
    }

    .container {
        @media (min-width: $screen-xl-min) {
            width: 1300px;
        }
    }
    .copyright {
        margin: 0;
        padding-top: $line-height-computed;
        font-size: rem(18);
        text-align: right;
    }
}

.main-inner {
    @include yak-container();
}
.main-right {
    @media (min-width: $screen-sm-min) {
        float: right !important;
    }
}
